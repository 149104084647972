import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Layout, DemoDaySummary, NextLaunch } from '../components'
import { groupBy, get, filter } from 'lodash'
import { set } from 'lodash/fp'
import { isPast } from 'date-fns'
import { useSiteMetadata } from "../utils"

// TODO: How to query missions performantly?
export default ({ data }) => {
  const { title: siteTitle } = useSiteMetadata()
  const demoDays = data.allWpDemoDay.nodes.map((node) => {
    return set('fields.launchDate', new Date(node.fields.launchDate), node)
  })
  const missions = data.allWpMission.nodes
  const missionsByDemoDay = groupBy(missions, 'fields.demoDay.id')
  const pastDemoDays = filter(demoDays, ({ fields: { launchDate } }) => isPast(launchDate))

  return (
    <Layout>
      <Helmet title={`Demo Days - ${siteTitle}`} bodyAttributes={{ class: 'demo_days' }} />
      <header className="page-header mission">
        <div className="header-container">
          <div className="mission-intro">
            <h1 className="underline-header-white">Demo Day</h1>
            <p className="subheader">Anything new has to start somewhere.</p>
          </div>
        </div>
      </header>
      <section className="demo-day-story-container">
        <div className="story-block">
          <p>Early on in our company, it became apparent that we had assembled a team of creative builders. Lunch conversations quickly turned into window whiteboarding sessions of product and technology ideas.</p>
          <p>While these times were fun, we all felt we weren’t living up to our true spirit. Talking about stuff is great. Building is better. To put some structure around this, we established “Demo Day” - a half-day, internal pow-wow to show off some cool things we had all built. We had our doubts. When we would find the time to do this extra work to build something? What should I make? Will I embarrass myself with my idea?</p>
          <p>Pushing on through, the first demo day arrived, and all those concerns quickly fell by the wayside. Each person showcased their creativity and passion in building an idea to a supportive team. We never attacked an idea or a person. The only concern on people’s minds was...what’s next for your idea?</p>
          <p>Demo Day continues to evolve and always will. It’s been a collection of individual projects and team projects. It’s a been full of simple hack ideas and robust product ideas. On that day though, all ideas are created equal. Each and every one is an expression of creative passion to take an idea and make it real.</p>
          <p>See a glimpse of our history below.</p>
        </div>
        <div className="images-demo-day">
          <div className="images-block">
            <img alt="" src="https://space-station.s3.amazonaws.com/sites/566b5aa0d7bcec0003000001/theme/images/demo-day-pic2.jpg?1593194907" className="nav-logo" />
            <img alt="" src="https://space-station.s3.amazonaws.com/sites/566b5aa0d7bcec0003000001/theme/images/demo-day-pic1.jpg?1593194907" className="nav-logo" />
          </div>
        </div>
      </section>
      <section className="previous-demo-days">
        {pastDemoDays.map(({ id, fields }) => (
          <DemoDaySummary key={id} {...fields} missions={get(missionsByDemoDay, id, [])} />
        ))}
      </section>
      <NextLaunch title="Our Next Launch Day" hideLink />
    </Layout>
  )
}

export const query = graphql`
  {
    allWpDemoDay(sort: { order: ASC, fields: [demo_day_fields___number] }) {
      nodes {
        id
        slug
        fields: demo_day_fields {
          description
          displayNumber
          launchDate
          number
          videoLink
          media {
            altText
            sourceUrl
          }
        }
      }
    }
    allWpMission {
      nodes {
        id
        slug
        fields: mission_fields {
          title
          description
          url
          teaser
          demoDay {
            ... on WpDemoDay {
              id
            }
          }
          teamMembers {
            name
          }
          commander {
            name
          }
          featured
        }
      }
    }
  }
`
